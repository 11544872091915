button {
    background: #e2e3e5;
    padding: 20px 40px;
    border-radius: 40px;
    border: none;
    font-size: 24px;
    display: block;
    margin: 80px auto;
    transition: 0.5s all ease;
    cursor: pointer;
    color: #1b1e21;
}

button:hover {
    transition: 0.5s all ease;
    transform: scale(1.1);
}