.alert {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    border-radius: 5px;
}

.alert_wrapper {
    text-align: center;
    padding: 20px 40px;
    font-size: 24px;
}