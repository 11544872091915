.information {
    padding: 40px 0;
}

.information_wrapper {

}

.wrapper_title {
    font-size: 32px;
}

.wrapper_title:not(:first-child) {
    margin-top: 80px;
}

.wrapper_lists {
    margin-left: 40px;
}

.wrapper_lists li a {
    color: black;
    font-size: 22px;
    text-decoration: underline;
}

.wrapper_contacts {
    list-style: none;
}

.wrapper_contacts li {
    font-size: 22px;

}