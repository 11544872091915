.chooseEntity {
    margin-top: 80px;
}

.chooseEntity .title {
    color: black;
    font-size: 24px;
    text-align: center;
}

.chooseEntity .wrapper_select {
    max-width: 300px;
    margin: auto;
}