header {
    width: 100%;
    border-bottom: 1px solid white;
    background: #574ec3;
}

.header_wrapper {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper_logo {
    width: 150px;
}

.wrapper_logo > img {
    width: 100%;
}

.wrapper_list {
    display: flex;
    align-items: center;
}

.wrapper_list a:not(:last-child) {
    margin-right: 35px;
    transition: 0.5s all ease;
}

.wrapper_list a {
    position: relative;
    overflow: hidden;
    font-size: 20px;
}

.wrapper_list a:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 2px;
    background: white;
    position: absolute;
    bottom: 0;
    left: -100%;
}

.wrapper_list a:hover:after {
    transition: 0.3s all ease;
    left: 0;
}

.preloader {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10;
    background: white;
    top: 0;
    left: 0;
}

.preloader .preloader_wrapper {
    width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text {
    text-align: center;
    margin: auto;
    font-size: 24px;
    width: 300px;
}