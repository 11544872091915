.title {
    font-size: 32px;
    color: black;
    margin: 30px 0;
}

.lists {
    color: black;
    font-size: 24px;
}

.list {
    display: flex;
    margin-top: 30px;
}

.actions {
    display: flex;
    align-items: center;
}

.actions > div {
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 50%;
    margin-left: 20px;
    position: relative;
    cursor: pointer;
    background: grey;
}

.actions > div img {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.title_list {

}
