.work {
    padding: 5px 0;
}

body {
    background: #ffffff;
}

.choose {
    color: white;
    font-size: 24px;
}