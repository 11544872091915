.modal_add_field {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(157, 149, 149, 0.31);
    z-index: 10;
}

.modal_add_field__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 40px;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.title {
    text-align: center;
    font-size: 24px;
    margin: 20px 0;
}

.buttons button {
    width: 45%;
    padding: 10px;
    text-align: center;
    background: #f5c6cb;
    cursor: pointer;
    font-size: 18px;
    color: #721c24;
    border-radius: 40px;
    border: none;
    margin-top: 30px;
}

.buttons > button:first-child {
    background: #d4edda;
    color: #155724;
}

.error {
    color: #721c24;
    text-align: center;
}