.createField {

}

.createField_wrapper {

}

.wrapper_setName {
    margin-top: 40px;
}

.wrapper_setName .title {
    font-size: 24px;
    text-align: center;
    color: black;
}

.setName_input {
    width: 350px;
    margin: 20px auto;

}

