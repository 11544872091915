.title {
    text-align: center;
    font-size: 24px;
    color: white;
    margin: 30px 0;
}

.DependedSettings {
    margin-top: 50px;
}

.functions {
    display: flex;
    justify-content: space-between;
}

.functions .fieldSetting {
    width: 30%;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.buttons > div {
    width: 45%;
    padding: 10px;
    text-align: center;
    background: #f5c6cb;
    cursor: pointer;
    font-size: 18px;
    color: #721c24;
    border-radius: 40px;
}

.buttons > div:first-child {
    background: #d4edda;
    color: #155724;
}