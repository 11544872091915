.content {
    max-width: 1400px;
    margin: auto;
}


body {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: white;
}

* {
    box-sizing: border-box;
}

input, select {
    outline: none;
    appearance: none;
}

ul {
    padding: 0;
    margin: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
