.title {
    color: rgba(0, 0, 0, 0.4);
    font-size: 18px;
    margin: 5px 0;
}

.item {
    width: 100%;
    margin: auto;
    list-style: none;
    border-bottom: 1px solid black;
}

.button {
    margin: 20px 0;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    margin-left: 10px;
}

.error {
    color: darkred;
    text-align: center;
    font-size: 18px;
}

.success {
    color: darkseagreen;
    text-align: center;
    font-size: 18px;
}