select.select {
    appearance: none;
    padding: 10px 20px;
    font-size: 14px;
    width: 100%;
    text-align-last: center;
    border: 1px solid rgba(0, 0, 0, 0.21);
    position: relative;
}

option {
    text-align: center;
}